<template>
  <v-card>
    <v-breadcrumbs :items="breadcrumbs">
      <template v-slot:divider>
        <v-icon>mdi-forward</v-icon>
      </template>
    </v-breadcrumbs>
    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-form @submit.prevent="">
        <v-card>
          <v-card-title>
            <span class="headline">{{ integration.name }}</span>
            <v-btn  v-if="operation == 'upload'" color="green" dark> {{ operation }}</v-btn >
            <v-btn v-else color="red" dark> {{ operation }}</v-btn >
          </v-card-title>
          <v-card-text>
            <v-container>
             <v-row>
                <v-col
                  cols="12"
                  sm="6"
                >
                  <v-date-picker
                    v-model="dates"
                    range
                  ></v-date-picker>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                >
                  <v-text-field
                    v-model="dateRangeText"
                    label="Date range"
                    prepend-icon="mdi-calendar"
                    readonly
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="dialog = false">
              Close
            </v-btn>
            <v-btn
              color="yellow darken-1"
              dark
              @click="startDownload"
            >
              Run {{ operation }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <h4 class="text-center py-4">{{ system.systemName }}</h4>
    <div class="button-wrapper">
      <v-btn
        color="cyan lighten-1 mr-2 ml-2"
        dark
        @click="changeitemType('all')"
        >All</v-btn
      >
      <v-btn
        color="green lighten-1 mr-2 ml-2"
        dark
        @click="changeitemType('active')"
        >Active</v-btn
      >
      <v-btn color="yellow darken-2 mr-2 " dark @click="changeitemType('pause')"
        >Paused</v-btn
      >
      <v-btn color="red darken-4 mr-2 " dark @click="changeitemType('error')"
        >Error
      </v-btn>
      <v-btn color="green darken-4 mr-2 float-right" dark @click="runSync"
        ><v-icon>mdi-database-sync </v-icon>
        sync
      </v-btn>
    </div>

    <v-card-title>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="items"
      :options.sync="options"
      :server-items-length="total"
      :search="search"
      :footer-props="{
        'items-per-page-options': itemsPerPage
      }"
      class="elevation-1 text-center"
    >
      <template v-slot:item.action="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              medium
              v-bind="attrs"
              v-on="on"
              color="cyan"
              @click="downloadOperation(item, 'upload')"
            >
              mdi-database-arrow-down
            </v-icon>
          </template>
          <span>Upload data</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              medium
              v-bind="attrs"
              v-on="on"
              color="red"
              @click="downloadOperation(item, 'redownload')"
            >
              mdi-cloud-alert
            </v-icon>
          </template>
          <span>Redownload data</span>
        </v-tooltip>
      </template>
      <template v-slot:item.status="{ item }">
        <v-tooltip left v-if="item.status == 'active'">
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="green lighten-1" v-bind="attrs" v-on="on" dark>
              mdi-sticker-check
            </v-icon>
          </template>
          <span>active</span>
        </v-tooltip>
        <v-tooltip left v-else-if="item.status == 'pause'">
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="yellow darken-2" v-bind="attrs" v-on="on" dark>
              mdi-motion-pause
            </v-icon>
          </template>
          <span>pause</span>
        </v-tooltip>
        <v-tooltip left v-else-if="item.status == 'error'">
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="red darken-1" v-bind="attrs" v-on="on" dark>
              mdi-alert-decagram
            </v-icon>
          </template>
          <span>error</span>
        </v-tooltip>
      </template>
      <template v-slot:item.with_filter="{ item }">
        <v-tooltip left v-if="item.with_filter">
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="green lighten-1" v-bind="attrs" v-on="on" dark>
              mdi-check-circle
            </v-icon>
          </template>
          <span>with filter</span>
        </v-tooltip>
        <v-tooltip left v-else>
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="yellow darken-2" v-bind="attrs" v-on="on" dark>
              mdi-close-circle
            </v-icon>
          </template>
          <span>without filter</span>
        </v-tooltip>
      </template>
      <template v-slot:item.with_calculation="{ item }">
        <v-tooltip left v-if="item.with_calculation">
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="green lighten-1" v-bind="attrs" v-on="on" dark>
              mdi-check-circle
            </v-icon>
          </template>
          <span>with filter</span>
        </v-tooltip>
        <v-tooltip left v-else>
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="yellow darken-2" v-bind="attrs" v-on="on" dark>
              mdi-close-circle
            </v-icon>
          </template>
          <span>without filter</span>
        </v-tooltip>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import store from '@/store/index'
import IntegrationService from '@/services/IntegrationService.js'
import SystemService from '@/services/SystemService.js'
import { paginationMixin } from '@/mixins/PaginationMixin.js'

export default {
  mixins: [paginationMixin],
  data() {
    return {
      dates: [],
      dialog: false,
      operation: '',
      integration: {},
      headers: [
        {
          text: 'ID',
          align: 'center',
          value: 'integration_id'
        },
        {
          text: 'Title',
          align: 'center',
          filterable: false,
          value: 'name'
        },
        { text: 'Site url', value: 'url' },
        { text: 'Smart code', value: 'smart_code' },
        { text: 'Status', value: 'status' },
        { text: 'With filter', value: 'with_filter' },
        { text: 'With calculation', value: 'with_calculation' },
        { text: 'Actions', value: 'action', sortable: false }
      ]
    }
  },
  created() {
    this.setBreadcrumbs([
      { text: 'Home', to: { path: '/' } },
      {
        text: ':systems',
        to: {
          name: 'systemsByType',
          params: { system_type: this.system.systemType }
        }
      },
      { text: ':system' } // placeholder
    ])
    this.replaceBreadcrumb({
      find: ':system',
      replace: {
        text: `${this.system.systemName} integrations`,
        to: {
          name: 'systemIntegrations',
          params: { system_id: this.system.systemId }
        },
        disabled: true
      }
    })
    this.replaceBreadcrumb({
      find: ':systems',
      replace: {
        text: this.system.systemTypeName,
        to: {
          name: 'systemsByType',
          params: {
            system_type: this.system.systemType,
            TypeName: this.system.systemTypeName
          }
        }
      }
    })
  },
  methods: {
    changeitemType(type) {
      this.$router
        .push({
          name: 'systemIntegrations',
          params: { systemId: this.systemId },
          query: { type: type }
        })
        .catch(() => {})
    },
    getDataFromAPI(type, offset) {
      // console.log(this.options)
      return new Promise((resolve, reject) => {
        const { sortBy, sortDesc, page, itemsPerPage } = this.options
        let items = []
        type = type !== undefined ? type : this.itemType
        const query = this.$route.query
        let total = 0
        let sort_by = sortBy.length == 1 ? sortBy[0] : 'integration_id'
        let sort = sortDesc.length && sortDesc[0] == 1 ? -1 : 1
        offset = offset !== 0 ? itemsPerPage * (page - 1) : 0
        IntegrationService.getIntegrations(
          this.$route.params.system_id,
          itemsPerPage,
          offset || 0,
          sort_by,
          sort,
          type,
          this.search
        )
          .then(response => {
            // console.log(response.data.results)
            items = response.data.results
            total = response.data.count
            resolve({ items, total })
          })
          .catch(error => {
            console.log(error)
            store.dispatch('setLoader', false)
          })
      })
    },
    downloadOperation(integration, operation) {
      this.integration = integration
      this.operation = operation
      this.dialog = true
    },
    startDownload(){
      if (this.dates.length != 2) {
        const notification = {
          type: 'error',
          message: `invalid dates params`
        }
        this.$store.dispatch('notification/add', notification, {
          root: true
        })
        this.dialog = false
      } else {
        let data  = {
        system_id: this.systemId,
          integration_id: this.integration.integration_id,
          dates: this.dates,
          operation: this.operation,
          status: 'executable',
          user_id: this.$store.getters['auth/getUserId']
        }
        IntegrationService.runDownload(data).then(() => {
          this.dates = []
          const notification = {
              type: 'success',
              message: `Start ${this.operation} for ${this.integration.integration_id}`
            }
            this.$store.dispatch('notification/add', notification, {
              root: true
            })
            this.dialog = false
        }).catch(error => {
          let status = error.response.data.detail
          if (status === undefined) {
            status = 'server error'
          } 
          const notification = {
            type: 'error',
            message: `${status}`
          }
          this.$store.dispatch('notification/add', notification, {
            root: true
          })
          this.$store.dispatch('setLoader', false)
        })
      }
      
    },
    runSync() {
      let system = {}
      SystemService.getSystem(this.systemId)
        .then(response => {
          system = response.data
          IntegrationService.runSync(this.systemId, system.smart_codes)
            .then(() => {
              const notification = {
                type: 'success',
                message: `Synchronization for ${system.smart_codes} finished.`
              }
              this.$store.dispatch('notification/add', notification, {
                root: true
              })
            })
            .catch(error => {
              console.log(error.response)
              this.$store.dispatch('setLoader', false)
            })
        })
        .catch(error => {
          console.log(error.response)
          this.$store.dispatch('setLoader', false)
        })
    }
  },
  computed: {
    systemId() {
      return this.$route.params.system_id
    },
    system() {
      return this.$store.state['systems']['system']
    },

    breadcrumbs() {
      return this.$store.state.breadcrumbs.breadcrumbs
    },
    dateRangeText () {
        return this.dates.join(' ~ ')
      },
  }
}
</script>

<style scoped></style>
