import apiClient from '@/services/ApiClient.js'

export default {
  runSync(systemId, smartCodes) {
    return apiClient.post('/integrations/sync/', {
      system_id: systemId,
      smart_codes: smartCodes
    })
  },
  getIntegrations(systemId, limit, offset, sortBy, sort, status, search) {
    let url = `/integrations/?system_id=${systemId}&limit=${limit}&offset=${offset}&sort_by=${sortBy}&sort=${sort}`
    if (search !== undefined && search.length >= 4) {
      url += `&search=${search}`
    }
    if (status !== 'all') {
      url += `&status=${status}`
    }
    return apiClient.get(url)
  },
  runDownload(data) {
    return apiClient.post('/integrations/operation/', data)
  },
  getOperations(limit, offset, sortBy, sort, status, search) {
    let url = `/integrations/operation/?limit=${limit}&offset=${offset}&sort_by=${sortBy}&sort=${sort}`
    if (search !== undefined && search !== '') {
      url += `&search=${search}`
    }
    if (status !== 'all') {
      url += `&status=${status}`
    }
    return apiClient.get(url)
  }
}
